export const environment = {
  name: 'MobileTracking APP',
  blankImage: 'http://localhost:4200/assets/imgs/spin.svg',
  apiUrl: 'https://estrenatubolso.tonative.app/api',
  maxPagesTables: 5,
  orderStatus: [
    { value: 'Pago aceptado', label: 'Pago aceptado' },
    { value: 'Enviado', label: 'Enviado' },
    { value: 'Entregado', label: 'Entregado' },
    { value: 'Cancelado', label: 'Cancelado' },
    { value: 'Reembolsado', label: 'Reembolsado' },
    { value: 'Error en pago', label: 'Error en pago' },
    { value: 'Preparación en curso', label: 'Preparación en curso' },
    { value: 'BORRAR-Pedido pendiente por falta de stock (pagado)', label: 'BORRAR-Pedido pendiente por falta de stock (pagado)' },
    { value: 'BORRAR-En espera de pago por transferencia bancaria', label: 'BORRAR-En espera de pago por transferencia bancaria' },
    { value: 'Reembolso parcial', label: 'Reembolso parcial' },
    { value: 'Sequra: en revisión', label: 'Sequra: en revisión' },
    { value: 'Sequra: Aprobado', label: 'Sequra: Aprobado' },
    { value: 'Sequra: Cancelado', label: 'Sequra: Cancelado' },
  ]
};